import axios, { AxiosInstance } from 'axios';

const BASE_URL = 'http://localhost:8000';
const API_KEY = 'a6DSSX781K6iOwZ69quQVdhoJ6gZZ7JI6pGerbU7LNXgmdl3GOukDCuoGDdA9rNcq6XYYYL4PqMoxpZo7Upa82hYiThF_mS1gYuucfuWo95fkwK7L6RnGGlNNVI8uTy1';

const api: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Token': API_KEY,
    'Content-Type': 'application/json',
  },
});

// Types
export interface Function {
  uuid: string;
  name: string;
  code: string;
  parameters: string;
  created_at: string;
  updated_at: string;
}

export interface FunctionChain {
  id: string;
  name: string;
  description: string;
  steps: { function: string }[];
  public: boolean;
}

// Function-related API calls
export const loadFunction = (name: string, functionCode: string) => {
  const encodedFunction = btoa(functionCode); // Base64 encoding
  return api.post('/load_function', { name, code: encodedFunction });
};

export const executeFunction = (functionName: string, args: any[] = [], kwargs: Record<string, any> = {}) => {
  return api.post('/execute_function', { function_name: functionName, args, kwargs });
};

export const getFunction = (functionUuid: string) => {
  console.log('Fetching function with UUID:', functionUuid);
  return api.get(`/functions/${functionUuid}`);
};

export const getFunctions = () => api.get('/functions');

export const getFunctionMetrics = (functionName: string) => {
  return api.get(`/function/${functionName}/metrics`);
};

export const renameFunction = (functionUuid: string, newName: string) => {
  return api.patch(`/functions/${functionUuid}/rename`, { new_name: newName });
};

export const deleteFunction = (functionId: string) => {
  return api.delete(`/functions/${functionId}`);
};

export const createFunction = (functionData: { name: string, code: string }) => {
  return api.post('/functions/load_function', functionData);
};

export const updateFunction = (functionUuid: string, functionData: { code: string }) => {
  return api.put(`/functions/${functionUuid}`, { code: functionData.code });
};

// Function Chain-related API calls
export const getFunctionChains = () => {
  return api.get('/function_chains');
};

export const getFunctionChain = (chainId: string) => {
  return api.get(`/function_chains/${chainId}`);
};

export const createFunctionChain = (chainData: {
  name: string,
  description: string,
  steps: { function: string }[],
  public: boolean
}) => {
  return api.post('/function_chains/', chainData);
};

export const updateFunctionChain = (chainId: string, chainData: Partial<FunctionChain>) => {
  return api.put(`/function_chains/${chainId}`, chainData);
};

export const deleteFunctionChain = (chainId: string) => {
  console.log('Deleting function chain:', chainId);
  return api.delete(`/function_chains/${chainId}`);
};

export const executeFunctionChain = (executionData: {
  chain_id: string,
  input_data: Record<string, any>,
  match_id: string | null
}) => {
  return api.post('/function_chains/execute', executionData);
};

export default api;