import React, { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";

import App from "./App";

const root = createRoot(document.getElementById("root")!);

function Main() {
    

  useEffect(() => {
    document.title = "PlayFlow";
    const meta = document.querySelector("meta[name='description']");
    if (meta) {
      meta.setAttribute("content", "PlayFlow");
    } else {
      const newMeta = document.createElement("meta");
      newMeta.name = "description";
      newMeta.content = "PlayFlow";
      document.getElementsByTagName("head")[0].appendChild(newMeta);
    }
  }, []);

  return (
    <StrictMode>

      <App />
    </StrictMode>
  );
}

root.render(<Main />);