// @ts-nocheck

import React from 'react';
import { EdgeProps, getBezierPath } from 'reactflow';

const TurboEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}: EdgeProps) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <path
      id={id}
      style={style}
      className={`react-flow__edge-path ${id.startsWith('temp-edge') ? 'temp' : ''}`}
      d={edgePath}
      markerEnd={markerEnd}
    />
  );
};

export default TurboEdge;
