// @ts-nocheck


import React, { useState, useEffect, useRef } from 'react';
import { executeFunction, updateFunction, renameFunction, deleteFunction, createFunction, executeFunctionChain } from './api/api';
import MonacoEditor from '@monaco-editor/react';
import { FiSave, FiTrash2, FiPlay, FiEdit2 } from 'react-icons/fi';
import './SidePanel.css';

const SidePanel = ({ selectedFunction, onClose, onFunctionUpdate, onFunctionRename, onFunctionDelete, isCreatingFunction }) => {
  const [functionDetails, setFunctionDetails] = useState(null);
  const [functionCode, setFunctionCode] = useState('');
  const [params, setParams] = useState({});
  const [executionResult, setExecutionResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newFunctionName, setNewFunctionName] = useState('');
  const [currentFunctionName, setCurrentFunctionName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [panelWidth, setPanelWidth] = useState(400);
  const panelRef = useRef(null);

  useEffect(() => {
    if (selectedFunction) {
      console.log('Selected function updated:', selectedFunction);
      setFunctionDetails(selectedFunction);
      setFunctionCode(selectedFunction.code || '');
      setNewFunctionName(selectedFunction.name);
      setCurrentFunctionName(selectedFunction.name);
      
      // Parse parameters
      const paramList = selectedFunction.parameters
        ? selectedFunction.parameters.replace(/[()]/g, '').split(',').map(param => param.trim().split('=')[0])
        : [];
      const initialParams = Object.fromEntries(paramList.map(param => [param, '']));
      setParams(initialParams);
    }
  }, [selectedFunction]);

  const handleCodeChange = (value) => {
    setFunctionCode(value);
  };

  const handleParamChange = (paramName, value) => {
    setParams(prevParams => ({ ...prevParams, [paramName]: value }));
  };

  const handleSaveFunction = async () => {
    setIsLoading(true);
    try {
      if (isCreatingFunction) {
        if (!newFunctionName) {
          throw new Error("Function name is required");
        }
        const encodedFunction = btoa(functionCode); // Base64 encoding
        await createFunction({ name: newFunctionName, code: encodedFunction });
      } else {
        // Update existing function
        const encodedFunction = btoa(functionCode); // Base64 encoding
        await updateFunction(selectedFunction.uuid, { code: encodedFunction });
      }
      onFunctionUpdate();
      setIsEditing(false);
      if (isCreatingFunction) {
        onClose();
      }
    } catch (error) {
      console.error('Error saving function:', error);
      alert(error.response?.data?.detail || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExecuteFunction = async () => {
    setIsLoading(true);
    try {
      console.log('Selected function for execution:', selectedFunction);

      if (!selectedFunction || !selectedFunction.chainId) {
        throw new Error("Cannot execute function: no chain ID available");
      }

      const inputData = {};
      Object.entries(params).forEach(([key, value]) => {
        if (value !== '') {
          try {
            inputData[key] = JSON.parse(value);
          } catch (e) {
            inputData[key] = value;
          }
        }
      });

      const executionData = {
        chain_id: selectedFunction.chainId,
        input_data: inputData,
        match_id: null // You might want to add a way to specify match_id if needed
      };

      console.log('Executing chain with data:', executionData);
      const response = await executeFunctionChain(executionData);
      console.log('Chain execution response:', response);
      setExecutionResult(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error('Error executing function chain:', error);
      setExecutionResult('Error: ' + (error.response?.data?.detail || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRenameFunction = async () => {
    if (newFunctionName !== currentFunctionName) {
      setIsLoading(true);
      try {
        await onFunctionRename(selectedFunction.uuid, newFunctionName);
        setCurrentFunctionName(newFunctionName);
        setIsEditing(false);
      } catch (error) {
        console.error('Error renaming function:', error);
        alert(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleDeleteFunction = async () => {
    if (selectedFunction && selectedFunction.uuid) {
      await onFunctionDelete(selectedFunction.uuid);
      onClose();
    }
  };

  const handleMouseDown = (e) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = window.innerWidth - e.clientX;
    if (newWidth > 200 && newWidth < 2600) {
      setPanelWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      className={`side-panel ${selectedFunction || isCreatingFunction ? 'open' : ''}`}
      style={{ width: panelWidth }}
      ref={panelRef}
    >
      <div className="draggable-handle" onMouseDown={handleMouseDown}>
        <div className="draggable-icon"></div>
      </div>
      <div className="side-panel-content">
        <button className="close-btn" onClick={onClose}>&times;</button>
        {isLoading ? (
          <p>Loading function details...</p>
        ) : isCreatingFunction || functionDetails ? (
          <>
            <div className="function-header">
              {isCreatingFunction ? (
                <div className="create-function-input">
                  <input
                    type="text"
                    value={newFunctionName}
                    onChange={(e) => setNewFunctionName(e.target.value)}
                    placeholder="Enter function name"
                  />
                </div>
              ) : isEditing ? (
                <div className="rename-input">
                  <input
                    type="text"
                    value={newFunctionName}
                    onChange={(e) => setNewFunctionName(e.target.value)}
                    placeholder="Enter new function name"
                  />
                  <button onClick={handleRenameFunction} className="action-btn">
                    Save
                  </button>
                  <button onClick={() => setIsEditing(false)} className="action-btn">
                    Cancel
                  </button>
                </div>
              ) : (
                <>
                  <h2>{currentFunctionName}</h2>
                  {!isCreatingFunction && (
                    <div className="function-actions">
                      <button onClick={() => setIsEditing(true)} className="action-btn">
                        <FiEdit2 /> Rename
                      </button>
                      <button onClick={handleDeleteFunction} disabled={isLoading} className="action-btn delete">
                        <FiTrash2 /> Delete
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="editor-container">
              <MonacoEditor
                value={functionCode}
                onChange={handleCodeChange}
                language="python"
                theme="vs-dark"
                options={{
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                  fontSize: 14,
                  automaticLayout: true,
                }}
                className="monaco-editor"
              />
            </div>
            <div className="button-group">
              <button 
                onClick={handleSaveFunction} 
                disabled={isLoading}
                className="primary-btn"
              >
                <FiSave /> {isCreatingFunction ? 'Create Function' : 'Save Function'}
              </button>
            </div>
            {!isCreatingFunction && selectedFunction && (
              <>
                <p className="function-parameters">Parameters: {functionDetails.parameters}</p>
                <p className="function-uuid">UUID: {functionDetails.uuid || 'N/A'}</p>
                <p className="function-chain">Chain ID: {functionDetails.chainId || 'N/A'}</p>
                <p className="function-created">Created: {new Date(functionDetails.created_at).toLocaleString()}</p>
                <p className="function-updated">Last Updated: {new Date(functionDetails.updated_at).toLocaleString()}</p>
                <h3 className="section-title">Execute Function</h3>
                {Object.entries(params).map(([paramName, paramValue]) => (
                  <div key={paramName} className="param-input">
                    <label>{paramName}</label>
                    <input
                      type="text"
                      value={paramValue}
                      onChange={(e) => handleParamChange(paramName, e.target.value)}
                      placeholder={`Enter ${paramName}`}
                    />
                  </div>
                ))}
                <button 
                  onClick={handleExecuteFunction} 
                  disabled={isLoading || !selectedFunction.chainId}
                  className="secondary-btn"
                >
                  <FiPlay /> Execute Function Chain
                </button>
                {executionResult && (
                  <div className="execution-result">
                    <h3>Execution Result:</h3>
                    <pre>{executionResult}</pre>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <p>Select a function to view details or create a new one.</p>
        )}
      </div>
    </div>
  );
};

export default SidePanel;