

import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import FunctionIcon from './FunctionIcon';
import { FiX } from 'react-icons/fi';

export type TurboNodeData = {
  id: string;
  chainId: string;
  functionId: string;
  title: string;
  subline: string;
  onRemove: (nodeData: TurboNodeData) => void;
};

const TurboNode = memo(({ data }: NodeProps<TurboNodeData>) => {
  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    console.log('Remove button clicked for node:', data.id);
    data.onRemove(data);
  };

  return (
    <div className="turbo-node">
      <button className="delete-button" onClick={handleRemove}>
        <FiX />
      </button>
      <div className="wrapper gradient">
        <div className="inner">
          <div className="body">
            <div className="icon">
              <FunctionIcon />
            </div>
            <div>
              <div className="title">{data.title}</div>
              {data.subline && <div className="subline">{data.subline}</div>}
            </div>
          </div>
          <Handle
            type="target"
            position={Position.Left}
            id="left"
            style={{ left: -5 }}
          />
          <Handle
            type="source"
            position={Position.Right}
            id="right"
            style={{ right: -5 }}
          />
        </div>
      </div>
    </div>
  );
});

export default TurboNode;