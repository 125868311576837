// @ts-nocheck


import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  NodeToolbar,
  useStoreApi,
  Position,
  Node,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { getFunctions, deleteFunction, getFunction, renameFunction, createFunctionChain, getFunctionChains, updateFunctionChain, getFunctionChain, executeFunctionChain } from './api/api';
import FunctionsLibrary from './FunctionsLibrary';
import TurboNode from './TurboNode';
import './TurboNode.css';
import TurboEdge from './TurboEdge';

import './styles.css';
import './FunctionsLibrary.css';

import SidePanel from './SidePanel';

const nodeTypes = { turbo: TurboNode };
const edgeTypes = { turbo: TurboEdge };

type NodeData = {
  id: string;
  chainId: string;
  functionId: string;
  title: string;
  subline: string;
  onRemove: (nodeData: NodeData) => void;
};

const ReactFlowWrapper = () => {
  const [functions, setFunctions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { project, getIntersectingNodes, getNode } = useReactFlow();
  const store = useStoreApi();

  const [selectedNode, setSelectedNode] = useState(null);
  const [isCreatingFunction, setIsCreatingFunction] = useState(false);
  const [functionChains, setFunctionChains] = useState([]);

  const MIN_DISTANCE = 150;
  const VERTICAL_TOLERANCE = 50; // Adjust this value to change how strict the horizontal alignment needs to be
  const DISCONNECT_DISTANCE = 200; // Distance at which nodes should disconnect

  const handleRemoveNode = useCallback((nodeData: NodeData) => {
    console.log('Remove node called with nodeData:', nodeData);

    // Get the current chain
    getFunctionChain(nodeData.chainId)
      .then(chainResponse => {
        const chain = chainResponse.data;
        console.log('Current chain:', chain);

        // Remove the function from the chain's function_ids
        const updatedFunctionIds = chain.steps.filter(step => step.function !== nodeData.functionId);

        // Prepare the updated chain data
        const updatedChainData = {
          ...chain,
          steps: updatedFunctionIds
        };

        // Update the chain
        updateFunctionChain(nodeData.chainId, updatedChainData)
          .then(() => {
            // Remove the node from the UI
            setNodes(prevNodes => prevNodes.filter((n) => n.id !== nodeData.id));
            setEdges(prevEdges => prevEdges.filter((e) => e.source !== nodeData.id && e.target !== nodeData.id));

            if (updatedFunctionIds.length === 0) {
              console.log('Chain deleted due to empty function list');
              // Remove all nodes and edges related to this chain
              setNodes(prevNodes => prevNodes.filter((n) => n.data.chainId !== nodeData.chainId));
              setEdges(prevEdges => prevEdges.filter((e) => !e.id.startsWith(nodeData.chainId)));
            }
            console.log('Function removed from chain successfully');
          })
          .catch(error => {
            console.error('Error updating function chain:', error);
            alert(`Failed to remove function from chain: ${error.message}`);
          });
      })
      .catch(error => {
        console.error('Error fetching chain:', error);
        alert(`Failed to fetch chain: ${error.message}`);
      });
  }, [setNodes, setEdges]);

  const convertFunctionChainsToNodesAndEdges = useCallback((chains) => {
    let nodes = [];
    let edges = [];
    if (Array.isArray(chains)) {
      chains.forEach((chain, chainIndex) => {
        if (chain.functions && Array.isArray(chain.functions)) {
          chain.functions.forEach((func, funcIndex) => {
            const nodeData: NodeData = {
              id: `${chain.id}-${func.uuid}`,
              chainId: chain.id,
              functionId: func.uuid,
              title: func.name || 'Unknown Function',
              subline: `Chain: ${chain.name}`,
              onRemove: handleRemoveNode,
            };
            nodes.push({
              id: nodeData.id,
              type: 'turbo',
              position: { x: funcIndex * 200, y: chainIndex * 150 },
              data: nodeData,
            });
            if (funcIndex > 0) {
              const prevNodeId = `${chain.id}-${chain.functions[funcIndex - 1].uuid}`;
              edges.push({
                id: `${chain.id}-edge-${funcIndex}`,
                source: prevNodeId,
                target: nodeData.id,
                type: 'turbo',
              });
            }
          });
        } else {
          console.warn(`Chain ${chain.id} has no functions or functions is not an array`);
        }
      });
    } else {
      console.error('Chains data is not an array:', chains);
    }
    console.log('Converted nodes:', nodes);
    console.log('Converted edges:', edges);
    return { nodes, edges };
  }, [handleRemoveNode]);

  const fetchFunctions = async () => {
    setIsLoading(true);
    try {
      const response = await getFunctions();
      setFunctions(response.data);
    } catch (error) {
      console.error('Error fetching functions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFunctionDetails = async (functionId) => {
    try {
      const response = await getFunction(functionId);
      return response.data;
    } catch (error) {
      console.error(`Error fetching details for function ${functionId}:`, error);
      return null;
    }
  };

  const fetchFunctionChains = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getFunctionChains();
      console.log('Function chains fetched:', response.data);
      
      const chains = response.data;

      if (!Array.isArray(chains)) {
        console.error('Unexpected response format for function chains:', chains);
        return;
      }

      const chainsWithFunctionDetails = await Promise.all(chains.map(async (chain) => {
        const functionsWithDetails = await Promise.all(chain.steps.map(async (step) => {
          try {
            const funcResponse = await getFunction(step.function);
            return { ...funcResponse.data, step };
          } catch (error) {
            console.error(`Error fetching details for function ${step.function}:`, error);
            return { uuid: step.function, name: 'Unknown Function', step };
          }
        }));
        return { ...chain, functions: functionsWithDetails };
      }));

      setFunctionChains(chainsWithFunctionDetails);
      
      // Convert function chains to nodes and edges
      const { nodes: chainNodes, edges: chainEdges } = convertFunctionChainsToNodesAndEdges(chainsWithFunctionDetails);
      setNodes(chainNodes);
      setEdges(chainEdges);
    } catch (error) {
      console.error('Error fetching function chains:', error);
    } finally {
      setIsLoading(false);
    }
  }, [convertFunctionChainsToNodesAndEdges, setNodes, setEdges]);

  useEffect(() => {
    fetchFunctions();
    fetchFunctionChains();
  }, [fetchFunctionChains]);

  const handleCreateFunction = () => {
    setSelectedNode({
      name: '',
      code: '',
      parameters: '',
      uuid: '',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    });
    setIsCreatingFunction(true);
  };

  const handleDeleteFunction = useCallback(async (functionUuid) => {
    if (window.confirm(`Are you sure you want to delete this function?`)) {
      setIsLoading(true);
      try {
        await deleteFunction(functionUuid);
        await fetchFunctions();
        await fetchFunctionChains(); // Add this line to refresh the canvas
        console.log(`Function with UUID "${functionUuid}" deleted successfully`);
        // Close the side panel if the deleted function was selected
        if (selectedNode && selectedNode.uuid === functionUuid) {
          setSelectedNode(null);
        }
      } catch (error) {
        console.error('Error deleting function:', error);
        alert(`Failed to delete function: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    }
  }, [fetchFunctions, fetchFunctionChains, selectedNode]);

  const handleFunctionRename = useCallback(async (functionUuid: string, newName: string) => {
    try {
      await renameFunction(functionUuid, newName);
      // Update the nodes state
      setNodes((nds) =>
        nds.map((node) => {
          if (node.data.functionId === functionUuid) {
            return {
              ...node,
              data: {
                ...node.data,
                title: newName,
              },
            };
          }
          return node;
        })
      );
      // Update the functions list
      setFunctions((funcs) =>
        funcs.map((func) => {
          if (func.uuid === functionUuid) {
            return { ...func, name: newName };
          }
          return func;
        })
      );
      // Fetch updated functions list
      await fetchFunctions();
    } catch (error) {
      console.error('Error renaming function:', error);
    }
  }, [fetchFunctions, setNodes, setFunctions]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const reactFlowBounds = event.currentTarget.getBoundingClientRect();
      const data = JSON.parse(event.dataTransfer.getData('application/reactflow'));

      // Check if the dropped element is valid
      if (typeof data.functionName === 'undefined' || !data.functionName || !data.functionUuid) {
        return;
      }

      const position = project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      // Create a new function chain with the dropped function
      try {
        const newChainResponse = await createFunctionChain({
          name: `Chain with ${data.functionName}`,
          description: `Function chain created with ${data.functionName}`,
          steps: [{ function: data.functionUuid }],
          public: false
        });

        console.log('New chain created:', newChainResponse);

        const newChainId = newChainResponse.data.id;
        const newNodeId = `${newChainId}-${data.functionUuid}`;

        const newNodeData: NodeData = {
          id: newNodeId,
          chainId: newChainId,
          functionId: data.functionUuid,
          title: data.functionName,
          subline: `Chain: ${newChainResponse.data.name}`,
          onRemove: handleRemoveNode,
        };

        const newNode = {
          id: newNodeId,
          type: 'turbo',
          position,
          data: newNodeData,
        };

        setNodes((nds) => nds.concat(newNode));

        await fetchFunctionChains();
      } catch (error) {
        console.error('Error creating function chain:', error);
      }
    },
    [project, setNodes, fetchFunctionChains, handleRemoveNode]
  );

  const onNodeClick = useCallback(async (event, node) => {
    // Check if the click event originated from the remove button
    if (event.target.closest('.remove-node-button')) {
      return; // Exit the function early if the click was on the remove button
    }

    console.log('Node clicked:', node);
    try {
      const functionDetails = await getFunction(node.data.functionId);
      console.log('Function details fetched:', functionDetails.data);
      setSelectedNode({
        ...functionDetails.data,
        chainId: node.data.chainId
      });
      setIsCreatingFunction(false);
    } catch (error) {
      console.error('Error fetching function details:', error);
    }
  }, []);

  const onPanelClose = useCallback(() => {
    setSelectedNode(null);
    setIsCreatingFunction(false);
  }, []);

  const getClosestEdge = useCallback((node: Node) => {
    console.log('getClosestEdge called for node:', node);
    const { nodeInternals } = store.getState();
    const storeNodes = Array.from(nodeInternals.values());

    const closestConnection = storeNodes.reduce(
      (res, n) => {
        if (n.id !== node.id) {
          const nodeRight = node.position.x + (node.width || 0);
          const nodeLeft = node.position.x;
          const nRight = n.position.x + (n.width || 0);
          const nLeft = n.position.x;
          
          const distanceRightToLeft = nLeft - nodeRight;
          const distanceLeftToRight = nodeLeft - nRight;
          const verticalDifference = Math.abs(n.position.y - node.position.y);

          console.log(`Checking node ${n.id}, right to left distance: ${distanceRightToLeft}, left to right distance: ${distanceLeftToRight}, vertical difference: ${verticalDifference}, chainId: ${n.data.chainId}`);

          if (distanceRightToLeft > 0 && distanceRightToLeft < MIN_DISTANCE && verticalDifference < VERTICAL_TOLERANCE) {
            if (distanceRightToLeft < res.distance) {
              return { distance: distanceRightToLeft, node: n, direction: 'rightToLeft' };
            }
          }

          if (distanceLeftToRight > 0 && distanceLeftToRight < MIN_DISTANCE && verticalDifference < VERTICAL_TOLERANCE) {
            if (distanceLeftToRight < res.distance) {
              return { distance: distanceLeftToRight, node: n, direction: 'leftToRight' };
            }
          }
        }
        return res;
      },
      { distance: Number.MAX_VALUE, node: null, direction: null }
    );

    if (!closestConnection.node) {
      console.log('No close node found');
      return null;
    }

    console.log('Closest node:', closestConnection.node, 'Direction:', closestConnection.direction);

    const edge = closestConnection.direction === 'rightToLeft'
      ? {
          id: `${node.id}-${closestConnection.node.id}`,
          source: node.id,
          target: closestConnection.node.id,
          sourceHandle: 'right',
          targetHandle: 'left',
          type: 'turbo',
        }
      : {
          id: `${closestConnection.node.id}-${node.id}`,
          source: closestConnection.node.id,
          target: node.id,
          sourceHandle: 'right',
          targetHandle: 'left',
          type: 'turbo',
        };

    console.log('Returning edge:', edge);
    return edge;
  }, [store]);

  const onNodeDrag = useCallback(
    (_, node) => {
      console.log('onNodeDrag called for node:', node);
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        console.log('Current edges:', es);
        const nextEdges = es.filter((e) => {
          // Remove only temporary edges related to the current dragged node
          if (e.id.startsWith('temp-') && (e.source === node.id || e.target === node.id)) {
            return false;
          }
          
          // Check if existing edges connected to the dragged node should be removed due to distance
          if (e.source === node.id || e.target === node.id) {
            const sourceNode = getNode(e.source);
            const targetNode = getNode(e.target);
            if (sourceNode && targetNode) {
              const dx = sourceNode.position.x - targetNode.position.x;
              const dy = sourceNode.position.y - targetNode.position.y;
              const distance = Math.sqrt(dx * dx + dy * dy);
              if (distance > DISCONNECT_DISTANCE) {
                console.log(`Removing edge ${e.id} due to distance`);
                return false;
              }
            }
          }
          // Ensure all existing edges are solid
          e.style = { ...e.style, strokeDasharray: 'none' };
          return true;
        });
        console.log('Filtered edges:', nextEdges);

        if (closeEdge) {
          console.log('Adding temporary edge:', closeEdge);
          nextEdges.push({
            ...closeEdge,
            id: `temp-${closeEdge.id}`,
            style: { stroke: '#bbb', strokeWidth: 2, strokeDasharray: '5 5' },
            className: 'temp',
          });
        } else {
          console.log('No close edge found');
        }

        console.log('New edges state:', nextEdges);
        return nextEdges;
      });
    },
    [getClosestEdge, setEdges, getNode]
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      console.log('onNodeDragStop called for node:', node);
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        console.log('Current edges:', es);
        const nextEdges = es.filter((e) => {
          // Remove only temporary edges related to the current dragged node
          if (e.id.startsWith('temp-') && (e.source === node.id || e.target === node.id)) {
            return false;
          }
          
          // Check if existing edges connected to the dragged node should be removed due to distance
          if (e.source === node.id || e.target === node.id) {
            const sourceNode = getNode(e.source);
            const targetNode = getNode(e.target);
            if (sourceNode && targetNode) {
              const dx = sourceNode.position.x - targetNode.position.x;
              const dy = sourceNode.position.y - targetNode.position.y;
              const distance = Math.sqrt(dx * dx + dy * dy);
              if (distance > DISCONNECT_DISTANCE) {
                console.log(`Removing edge ${e.id} due to distance`);
                return false;
              }
            }
          }
          return true;
        });
        console.log('Filtered edges:', nextEdges);

        if (closeEdge) {
          console.log('Adding permanent edge:', closeEdge);
          nextEdges.push({
            ...closeEdge,
            style: { stroke: 'url(#edge-gradient)', strokeWidth: 2, strokeDasharray: 'none' },
            animated: true,
          });

          // Update both function chains if a new connection was made
          const sourceChainId = node.data.chainId;
          const targetNode = getNode(closeEdge.target);
          const targetChainId = targetNode?.data.chainId;

          if (sourceChainId && targetChainId) {
            getFunctionChain(sourceChainId).then(sourceChainResponse => {
              const sourceChain = sourceChainResponse.data;
              updateFunctionChain(sourceChainId, {
                steps: [...new Set([...sourceChain.steps, { function: node.data.functionId }, { function: targetNode.data.functionId }])],
              }).catch(error => {
                console.error('Error updating source function chain:', error);
              });

              if (sourceChainId !== targetChainId) {
                getFunctionChain(targetChainId).then(targetChainResponse => {
                  const targetChain = targetChainResponse.data;
                  updateFunctionChain(targetChainId, {
                    steps: [...new Set([...targetChain.steps, { function: node.data.functionId }, { function: targetNode.data.functionId }])],
                  }).catch(error => {
                    console.error('Error updating target function chain:', error);
                  });
                });
              }
            });
          }
        } else {
          console.log('No close edge found');
        }

        console.log('New edges state:', nextEdges);
        return nextEdges;
      });
    },
    [getClosestEdge, setEdges, getNode]
  );

  return (
    <div className="app">
      <FunctionsLibrary 
        functions={functions} 
        onCreateFunction={handleCreateFunction}
        onDeleteFunction={handleDeleteFunction}
        isLoading={isLoading}
      />
      <div className="reactflow-wrapper">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
        >
          <Controls />
          <Background />
          <svg style={{ position: 'absolute', width: 0, height: 0 }}>
            <defs>
              <linearGradient id="edge-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#e92a67" />
                <stop offset="50%" stopColor="#a853ba" />
                <stop offset="100%" stopColor="#2a8af6" />
              </linearGradient>
            </defs>
          </svg>
        </ReactFlow>
      </div>
      <SidePanel
        selectedFunction={selectedNode}
        onClose={onPanelClose}
        onFunctionUpdate={fetchFunctions}
        onFunctionRename={handleFunctionRename}
        onFunctionDelete={handleDeleteFunction} // Add this line
        isCreatingFunction={isCreatingFunction}
      />
    </div>
  );
};

const App = () => (
  <ReactFlowProvider>
    <ReactFlowWrapper />
  </ReactFlowProvider>
);

export default App;