// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { FiBox } from 'react-icons/fi';
import './FunctionsLibrary.css';

interface Function {
  uuid: string;
  name: string;
}

interface FunctionsLibraryProps {
  functions: Function[];
  onCreateFunction: () => void;
  onDeleteFunction: (functionId: string) => void;
  isLoading: boolean;
}

const FunctionIcon: React.FC = () => <FiBox />;

const FunctionsLibrary: React.FC<FunctionsLibraryProps> = ({
  functions,
  onCreateFunction,
  onDeleteFunction,
  isLoading
}) => {
  const onDragStart = (event, functionName, functionUuid) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify({
      functionName,
      functionUuid,
      type: 'turbo'
    }));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="functions-library">
      <h2>Functions Library</h2>
      <div
        className="draggable-node create-function"
        onClick={onCreateFunction}
      >
        <FiPlus />
        <span>Create Function</span>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        functions.map((func) => (
          <div
            key={func.uuid}
            className="draggable-node function-item"
          >
            <div
              className="function-drag-area"
              draggable
              onDragStart={(event) => onDragStart(event, func.name, func.uuid)}
            >
              <FunctionIcon />
              <span>{func.name}</span>
            </div>
            <button
              className="delete-function-btn"
              onClick={() => onDeleteFunction(func.uuid)}
              title="Delete function"
            >
              <FiTrash2 />
            </button>
          </div>
        ))
      )}
    </div>
  );
};

export default FunctionsLibrary;